import COLORS from '../../../lib/helpers/color-helper';

interface Props {
  fillColor?: string;
}
export default function EditSVG({ fillColor = COLORS.copyDark }: Props) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 13 12'
      fill={fillColor}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.18525 0.69582C8.89426 -0.0131909 10.0438 -0.0131892 10.7528 0.69582L11.5263 1.46929C12.2353 2.1783 12.2353 3.32783 11.5263 4.03684L4.26413 11.299C3.92365 11.6395 3.46187 11.8307 2.98036 11.8307H0.886503C0.613042 11.8307 0.391357 11.609 0.391357 11.3356V9.24173C0.391357 8.76022 0.582636 8.29843 0.923115 7.95795L8.18525 0.69582ZM10.0526 1.39606C9.73028 1.07378 9.20777 1.07378 8.88549 1.39606L7.52849 2.75306L9.46903 4.6936L10.826 3.3366C11.1483 3.01432 11.1483 2.4918 10.826 2.16953L10.0526 1.39606ZM8.76878 5.39384L6.82825 3.4533L1.62336 8.6582C1.46859 8.81296 1.38165 9.02286 1.38165 9.24173V10.8404H2.98036C3.19922 10.8404 3.40913 10.7535 3.56389 10.5987L8.76878 5.39384Z'
        fill={fillColor}
        stroke={fillColor}
        strokeWidth='0.1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
