'use client';

import { useMemo } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import LoadableImage from '../loadable-image';
import Tile from '.';

import { images, titlePhotoPath, formatMoney, analyticsBookData, priceDropPrice, mapSortBy, mapQueryBy } from 'sdk';
import Links from '@/lib/helpers/link-helper';
import useCart from '@/lib/hooks/use-cart';
import useShelves from '@/lib/hooks/use-shelves';
import useAuthenticatedAction from '~/lib/hooks/use-authenticated-action';

import { ProductViewSource, Shelf, Title } from 'types';

import styles from '@/styles/components/tiles/title.module.scss';
import useAnalytics from '@/lib/hooks/use-analytics';
import SessionForm from '@/components/session-form';
import CartButtonEmptySVG from '@/assets/svg/icons/cart-button-empty';
import CartButtonFilledSVG from '@/assets/svg/icons/cart-button-filled';
import ShelfTileDropdown from '@/components/shelf-tile-dropdown';
import WishFilledSVG from '@/assets/svg/icons/wish-filled-new';
import WishEmptySVG from '@/assets/svg/icons/wish-empty';
import Button from '@/components/button';
import Tooltip from '@/components/tooltip';
import StarFull from '@/assets/svg/icons/star-full';
import COLORS from '@/lib/helpers/color-helper';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export default function TitleTile(props: {
  title: Title;
  source: ProductViewSource;
  cartStyle?: 'default' | 'large';
  sold?: boolean;
  shelfData?: { shelf: Shelf; refetch: any; isOwner: boolean; };
}) {
  const { title, source } = props;
  const selectedListing = title.selected_listing;
  const { addToCart, isInCart, removeFromCart } = useCart();
  const { handleShelf, isTitleInShelf } = useShelves({ book: selectedListing, source });
  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: () => handleShelf({ title, type: 'title' }),
  });
  const { analyticEvents } = useAnalytics();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const SHOW_LARGE_CART = useMemo(
    () => props.cartStyle === 'large',
    [props.cartStyle]
  );

  function handleShelfClick(e) {
    e.preventDefault();
    handleAuthAction({
      title: 'Login to add to Your Shelves',
      message: "You need an account to add this to your shelves, please sign in with one of the methods below.",
      component: <SessionForm />,
    });
  }

  async function handleCartClick(e, book) {
    e.preventDefault();

    if (isInCart(book)) {
      await removeFromCart(selectedListing);
    } else {
      await addToCart({
        item: selectedListing,
        source: source || 'tile',
      });
    }
  }

  function handleAnalytics() {
    if (!title.selected_listing) return;
    analyticEvents.productClick({
      product: analyticsBookData(title?.selected_listing),
      source,
      index: 'titles',
      objectID: title.id,
    });
  }

  function renderShelf() {

    const renderIcon = () => {
      if (Boolean(props.shelfData) && props.shelfData.isOwner) {
        return (
          <Tooltip
            content='Edit this title in your shelf'
            position='left'
          >
            <div className={styles.wish}>
              <ShelfTileDropdown title={title} shelf={props.shelfData.shelf} refetch={props.shelfData.refetch} />
            </div>
          </Tooltip>
        );
      }

      return (
        <Tooltip
          content='Add this to a shelf'
          position='left'
        >
          <div onClick={handleShelfClick} className={styles.wish}>
            {isTitleInShelf(title.id) ? <WishFilledSVG /> : <WishEmptySVG />}
          </div>
        </Tooltip>
      );
    };

    return (
      <div className={styles['wish-position']}>
        {renderIcon()}
      </div>
    );
  }

  function renderAddToCart() {
    if (!selectedListing) return;
    const inCart = isInCart(selectedListing);

    if (SHOW_LARGE_CART) {
      return (
        <Button
          text='Add to Cart'
          style={inCart ? 'primary' : 'outline'}
          size='small'
          onPress={(e) => handleCartClick(e, selectedListing)}
        />
      );
    }

    return (
      <div className={styles['cart-position']}>
        <Tooltip content='Add this to your cart' position='left'>
          <div
            className={classNames(styles.cart)}
            onClick={(e) => handleCartClick(e, selectedListing)}
          >
            {inCart ? <CartButtonFilledSVG /> : <CartButtonEmptySVG />}
          </div>
        </Tooltip>
      </div>
    );
  }

  function renderPriceInfo() {
    if (selectedListing) {
      return (
        <div className={styles.info}>
          <div className={styles['info-price']}>
            <span>{formatMoney(title.selected_listing.amount)}</span>
            {priceDropPrice(selectedListing) && (
              <span className={styles['info-price-drop']}>
                {formatMoney(priceDropPrice(selectedListing))}
              </span>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.info}>
        <p className={styles['info-sold-out']}>Sold Out</p>
      </div>
    );
  }

  const renderRating = () => {
    if (!title?.avg_rating) return null;

    return (
      <div className={styles.rating}>
        <p className={styles['rating-content']}>
          {title?.avg_rating.toFixed(1)}
          <span>
            <StarFull size={16} fillColor={COLORS.yellow} />
          </span>
        </p>
      </div>
    );
  };

  function renderSoldBanner() {
    // @ts-ignore
    if (!title?.selected_listing?.sold) return;
    return (
      <div className={styles['sold-banner']}>
        <div className={styles['sold-text']}>Sold</div>
      </div>
    );
  }

  function renderSeeMoreButton() {
    return (
      <Button
        style='secondary'
        size='xtra-small'
        text={'See more like this'}
        onPress={(e) => {
          e.preventDefault();
          router.push(Links.searchWithQueryAppRouter({
            pathname,
            searchParams,
            path: '/search',
            options: {
              index: 'books',
              params: {
                q: '*',
                sort_by: mapSortBy('books'),
                query_by: mapQueryBy('books'),
                filter_by: `title_id:${title?.selected_listing?.title_id}`,
              },
              options: {}
            },
          }).url);
        }
        }
      />
    );
  }

  return (
    <Tile>
      <Link href={title?.selected_listing?.pinned_copy ? Links.book(title.selected_listing.id).show : Links.title(title).show} passHref onClick={() => handleAnalytics()}>

        <div className={styles['image-container']}>
          {images(titlePhotoPath(title)).book.tile && (
            <>
              <LoadableImage
                src={images(titlePhotoPath(title)).book.tile}
                alt={title.title}
                fill
              />
            </>
          )}
          {renderSoldBanner()}
          {/* {renderWishlist()} */}
          {renderShelf()}
        </div>
        <div className={styles['content-container']}>
          <div className={styles['content-top']}>
            <h2 className={styles.author}>{title.authors?.[0]?.name}</h2>
            {renderRating()}
          </div>
          <h1 className={styles.title}>{title.title}</h1>
          {!title?.selected_listing?.sold ?
            <div className={classNames(styles['content-bottom'], {
              [styles['content-bottom--large']]: SHOW_LARGE_CART
            })}>
              {renderPriceInfo()}
              {renderAddToCart()}
            </div>
            :
            renderSeeMoreButton()
          }
        </div>
      </Link>
    </Tile>
  );
}
