import { useState } from 'react';

import MoreIcon from '~/assets/svg/icons/more-horizontal';
import DropDown from '~/components/dropdown';
import Button from './button';

import ShelvesListModal from './shelves/shelves-list-modal';

import Trash from '~/assets/svg/icons/trash';
import EditSVG from '~/assets/svg/icons/edit';
import COLORS from '~/lib/helpers/color-helper';
import { Shelf, Title } from 'types';

import { useRouter } from 'next/router';
import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';
import useShelves from '~/lib/hooks/use-shelves';

import styles from '../styles/components/shelf-tile-dropdown.module.scss';

export default function ShelfTileDropdown({
  title,
  shelf,
  refetch,
}: {
  title: Title;
  shelf: Shelf;
  refetch: any;
}) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { dispatch } = useGlobalState();
  const { removeFromShelves } = useShelves({});


  const DropdownContainer = () => {

    const dropdownItems = [
      {
        label: 'Move shelf',
        icon: <EditSVG />,
        onClick: (e) => {
          e.preventDefault();
          dispatch({
            type: ActionType.ADD_MODAL_DATA,
            payload: {
              title: 'Add to shelves',
              component: (
                <ShelvesListModal
                  titleId={title.id}
                  bookId={title?.selected_listing?.id}
                  shelfIds={[shelf.id]}
                  callback={refetch}
                  originalShelfData={{ id: shelf.id, title: shelf.title }}
                  pinnedCopy={title?.selected_listing?.pinned_copy}
                />
              ),
            },
          });
        },
      },
      {
        label: 'Remove from shelf',
        icon: <Trash />,
        onClick: (e) => {
          e.preventDefault();
          dispatch({
            type: ActionType.ADD_MODAL_DATA,
            payload: {
              title: 'Remove from shelf',
              message: `Are you sure you want to remove ${title.title} from ${shelf.title}?`,
              component: (
                <div className={styles['remove-book-modal']}>
                  <Button
                    text='Cancel'
                    style='secondary'
                    onPress={() => {
                      dispatch({
                        type: ActionType.FLUSH_MODAL_DATA,
                      });
                    }}
                  />
                  <Button
                    text='Remove'
                    style='primary'
                    onPress={async () => {
                      //remove title from shelf
                      await removeFromShelves({
                        titleId: title.id,
                        titleText: title.title,
                        shelfIds: [shelf.id],
                        bookId: title.selected_listing.id,
                      });
                      refetch();
                    }}
                  />
                </div>
              ),
            },
          });
        },
      },
    ];

    return (
      <div className={styles['dropdown-container']}>
        {dropdownItems.map((item, index) => {
          return (
            <div
              key={index}
              className={styles['dropdown-container-item']}
              onClick={item.onClick}
            >
              <div className={styles['dropdown-container-icon']}>
                {item.icon}
              </div>
              <span className={styles['dropdown-container-label']}>
                {item.label}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={{ position: 'absolute' }}>
      <Button
        icon={<MoreIcon height={24} width={24} fillColor={COLORS.grey} />}
        style='clear'
        shape='circle'
        className={styles['tile-more-icon']}
        onPress={(e) => {
          e.preventDefault();
          setOpenDropdown(true);
        }}
      />
      <DropDown open={openDropdown} onHide={() => setOpenDropdown(false)}>
        <DropdownContainer />
      </DropDown>
    </div>
  );
}
